import React, { useState, useEffect } from 'react'
import { Router, Location } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import { CORE_CONFIG_FIELDS } from './utils/fragments'
import { ConfigProvider } from './contexts/Config'
import { OptionsProvider } from './contexts/Options'
import { RacesProvider } from './contexts/Races'
import { IntlProvider } from 'react-intl'
import { LOCALES } from './i18n/locales'
import messages from './i18n/messages'
import {
	createMuiTheme,
	ThemeProvider,
	makeStyles,
	StylesProvider,
	jssPreset,
} from '@material-ui/core/styles'
import { CssBaseline, Toolbar } from '@material-ui/core'
import CookieConsent from 'react-cookie-consent'
import { create } from 'jss'
import rtl from 'jss-rtl'
import {
	Root,
	getHeader,
	getDrawerSidebar,
	getSidebarTrigger,
	getSidebarContent,
	getCollapseBtn,
	getContent,
	getFooter,
	getStandardScheme,
} from '@mui-treasury/layout'
import { NavContent, AppHeader, AppFooter } from './components/MUILayout'
import { Departures, RankingOV, RankingRT, Pdf, StartList, Settings } from './views'
import { useStateWithLocalStorage } from './hooks'
import styled from 'styled-components'

const CONFIG_QUERY = gql`
	${CORE_CONFIG_FIELDS}
	query {
		config {
			...CoreConfigFields
		}
	}
`
const RACES_QUERY = gql`
	query {
		races {
			id
			name
			text
			isGroup
			isClass
			isCoef
			isAvg
			isScratch
		}
	}
`

const Header = getHeader(styled)
const DrawerSidebar = getDrawerSidebar(styled)
const SidebarTrigger = getSidebarTrigger(styled)
const SidebarContent = getSidebarContent(styled)
const CollapseBtn = getCollapseBtn(styled)
const Content = getContent(styled)
const Footer = getFooter(styled)
const standardScheme = getStandardScheme()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function App() {
	const [config, setConfig] = useState({})
	const [races, setRaces] = useState([])
	const { rallyCode, rallyType, primaryColor, secondaryColor } = config
	const [locale, setLocale] = useStateWithLocalStorage('locale', LOCALES.FRENCH) //TODO: add default language (FR || EN)
	const [isDarkMode, setIsDarkMode] = useStateWithLocalStorage('isDarkMode')

	const [data] = useState({
		header: true,
		nav: true,
		content: true,
		footer: rallyType === 'DKC' ? false : true,
	})

	const { loading: configLoading, error: configError, data: configData } = useQuery(CONFIG_QUERY)
	const { loading: racesLoading, error: racesError, data: racesData } = useQuery(RACES_QUERY)

	useEffect(() => {
		document.body.dir = locale === 'ar-SA' ? 'rtl' : 'ltr'
	}, [locale])

	useEffect(() => {
		if (!configLoading && configData) {
			setConfig(configData.config)
		}
	}, [configLoading, configData])

	useEffect(() => {
		if (!racesLoading && racesData) {
			setRaces(racesData.races)
		}
	}, [racesLoading, racesData])

	const theme = createMuiTheme({
		direction: locale === 'ar-SA' ? 'rtl' : 'ltr',
		palette: {
			type: isDarkMode ? 'dark' : 'light',
			primary: {
				main: config.primaryColor ? primaryColor : isDarkMode ? '#ffffff' : '#949494',
			},
			secondary: {
				main: config.secondaryColor ? secondaryColor : isDarkMode ? '#ffffff' : '#757575',
			}, //contrastThreshold: 8,
			tableBackground: isDarkMode ? '#424242' : '#fff',
			tableBackgroundOdd: isDarkMode ? '#757575' : '#e9e9e9',
			tableBackgroundHeader: isDarkMode ? '#424242' : '#fff',
		},
		typography: {
			fontSize: 13, //NOTE: all application font-size
		},
		overrides: {
			MuiDrawer: {
				overflow: 'hidden',
			},
			MuiToggleButton: {
				// Override the styling for selected toggle buttons
				root: {
					'&$selected': {
						backgroundColor: secondaryColor,
						color: '#ffffff',
					},
				},
			},
		},
	})

	const useStyles = makeStyles(theme => ({
		root: {
			height: '100%',
		},
		header: {
			padding: 2,
			backgroundColor: primaryColor ? primaryColor : isDarkMode ? '#212121' : '#f5f5f5',
		},
		content: {
			height: `calc(100vh - 64px)`,
		},
		footer: {
			//marginTop: '-15px',
			//marginBottom: 25,
			//bottom: 0
		},
	}))
	const classes = useStyles()

	standardScheme.enableAutoCollapse('primarySidebar', 'xl')
	standardScheme.configureEdgeSidebar(builder => {
		builder.update('primarySidebar', config => {
			config.sm.collapsedWidth = 66
			config.xs.width = 250
			config.xs.headerMagnetEnabled = true
		})
	})

	const changeLocale = lang => {
		setLocale(lang)
	}

	if (configLoading || racesLoading) return 'Loading...'
	if (configError) return `Error! ${configError && configError.message}`
	if (racesError) return `Error! ${racesError && racesError.message}`

	return (
		!!races.length &&
		!racesLoading &&
		!!Object.keys(config).length &&
		!configLoading && (
			<ConfigProvider config={config}>
				<RacesProvider races={races}>
					<OptionsProvider>
						<IntlProvider locale={locale} messages={messages[locale]}>
							<StylesProvider jss={jss}>
								<Root scheme={standardScheme} className={classes.root}>
									{({ state: { sidebar }, setOpen }) => (
										<Location>
											{({ location }) => (
												<ThemeProvider theme={theme}>
													<CssBaseline />
													<Header className={classes.header}>
														<Toolbar>
															<SidebarTrigger sidebarId="primarySidebar" />
															{data.header && (
																<AppHeader
																	locale={locale}
																	changeLocale={changeLocale}
																	LOCALES={LOCALES}
																/>
															)}
														</Toolbar>
													</Header>
													<DrawerSidebar sidebarId="primarySidebar">
														<SidebarContent>
															{data.nav && (
																<NavContent
																	rallyCode={rallyCode}
																	location={location}
																	sidebar={sidebar}
																	setOpen={setOpen}
																	collapsed={sidebar.primarySidebar.collapsed}
																/>
															)}
														</SidebarContent>
														<CollapseBtn />
													</DrawerSidebar>
													<Content className={classes.content}>
														{data.content && (
															<Router>
																<RankingRT path="/stages" default />
																{config.rallyType !== 'TAO' && <RankingOV path="/overall" />}
																<Departures path="/departures" />
																<StartList path="/startlist" />
																<Pdf path="/pdf" />
																<Settings
																	path="/settings"
																	themeChange={setIsDarkMode}
																	isDarkMode={isDarkMode}
																/>
															</Router>
														)}
													</Content>
													<Footer className={classes.footer}>{data.footer && <AppFooter />}</Footer>
													<CookieConsent
														style={{
															fontSize: '10px',
															textAlign: 'center',
														}}
													>
														This website uses essential functional cookies to enhance the user
														experience.
													</CookieConsent>
												</ThemeProvider>
											)}
										</Location>
									)}
								</Root>
							</StylesProvider>
						</IntlProvider>
					</OptionsProvider>
				</RacesProvider>
			</ConfigProvider>
		)
	)
}

export default App
