import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { useConfig } from '../../hooks'
import { MenuListRaces, MenuListRT } from './'

const useStyles = props =>
	makeStyles(theme => ({
		root: {
			position: 'static',
		},
		rtMenu: {
			padding: 2,
			marginTop: 5,
			width: '90%',
			overflowX: 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			scrollbarWidth: 'none',
		},
		rtButton: {
			//height: '30px'
			//		backgroundColor: theme.palette.secondary.main,
			//	color: 'white',
			borderColor: theme.palette.secondary.main,
			color: theme.palette.secondary.main,
			borderRadius: '1em',
			padding: 5,
			whiteSpace: 'nowrap',
			//width: `${props.rallyType === 'TAO' ? '80px' : 'auto'}`,
		},
		rtInactive: {
			textDecoration: 'line-through',
		},
		disabledButton: {
			//color: '#aaaaff'
			//backgroundColor: '#ffffff'
		},
		rootMessage: {
			width: '100%',
			//padding: '16px',
			textAlign: 'center',
		},
		left: {},
		middle: {},
		right: {
			textAlign: 'right',
		},
		headerText: {
			fontWeight: 'bold',
			minWidth: 0,
			fontSize: 12,
			textAlign: 'center',
			marginLeft: '5px',
			//color: 'red',
		},
		'@keyframes blinker': {
			from: { opacity: 1 },
			to: { opacity: 0.5 },
		},
		blink: {
			animationDuration: '2s',
			animationName: '$blinker',
			animationIterationCount: 'infinite',
			animationDirection: 'alternate',
			animationTimingFunction: 'ease-in-out',
		},
	}))

const DAY_NAME_QUERY = gql`
	{
		dayName {
			id
			name
			text
		}
	}
`

function MenuRT({
	rankingSelectedRT,
	setRankingSelectedRT,
	rankingSelectedRace,
	setRankingSelectedRace,
	setRankingStages,
	setRankingPropsRT,
	isData,
}) {
	const { config } = useConfig()
	const { mixDisplayRaces: isMix, rallyType } = config
	const classes = useStyles({ rallyType })()
	const [dayName, setDayName] = useState(null)

	const { data: dayNameData } = useQuery(DAY_NAME_QUERY)

	useEffect(() => {
		if (dayNameData) {
			const dayString = `${dayNameData.dayName.name}: ${dayNameData.dayName.text}`
			setDayName(dayString)
		}
	}, [dayNameData])

	return (
		<React.Fragment>
			<div className={classes.root}>
				{config.rallyType === 'TAO' && dayName && (
					<span className={classes.headerText}>{dayName}</span>
				)}
				{!isMix && (
					<MenuListRaces
						rankingSelectedRace={rankingSelectedRace}
						setRankingSelectedRace={setRankingSelectedRace}
						setRankingSelectedRT={setRankingSelectedRT}
						classes={classes}
						rankingType={'RT'}
					/>
				)}
				<MenuListRT
					rankingSelectedRT={rankingSelectedRT}
					setRankingSelectedRT={setRankingSelectedRT}
					rankingSelectedRace={rankingSelectedRace}
					setRankingSelectedRace={setRankingSelectedRace}
					setRankingStages={setRankingStages}
					setRankingPropsRT={setRankingPropsRT}
					isData={isData}
					classes={classes}
				/>
			</div>
		</React.Fragment>
	)
}
export default MenuRT
