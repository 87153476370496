import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { CssBaseline } from '@material-ui/core'
import { DriveEta, LocalShipping } from '@material-ui/icons'
import { MUITable } from '../components/MUITable'
import { FormattedMessage } from 'react-intl'
import { Image } from 'cloudinary-react'
import { useConfig } from '../hooks'

const TEAMS_LIST_QUERY = gql`
	{
		teams {
			raceNumber
			teamNameComplete
			race {
				text
				isGroup
				isClass
				isCoef
				isAvg
				isScratch
			}
			cars {
				model
				year
				capacity
				averages {
					avg
					avgText
				}
				groupDisplay
				classDisplay
				coefficientDisplay
				period
				plateau
			}
			vehicle
			isRookie
			racingTeam
			isRiders
		}
	}
`

function StartList() {
	const { config } = useConfig()
	const { loading, error, data } = useQuery(TEAMS_LIST_QUERY)
	if (loading) return 'Loading...'
	if (error) return `Error! ${error.message}`
	const teams = data.teams

	const columns = [
		{
			Header: () => (
				<div style={{ textAlign: 'left' }}>
					<span style={{ textAlign: 'right' }}>{'#'}</span>
				</div>
			),
			accessor: 'raceNumber',
			maxWidth: 30,
			sticky: 'left',
			Cell: ({ value }) => (
				<div style={{ textAlign: 'center' }}>
					{' '}
					<span style={{ textAlign: 'right' }}>{value}</span>
				</div>
			),
		},
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'teamNameComplete',
			Cell: ({ cell }) => {
				//console.log('cell:', cell)
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 220,
			maxWidth: 300,
		},
		{
			Header:
				config.nameCar === 'Vehicle' ? (
					<FormattedMessage id="vehicle" />
				) : (
					<FormattedMessage id="car" />
				),
			accessor: 'cars[0].model',
			id: 'car',
			minWidth: 200,
			maxWidth: 220,
		},
		{
			Header: <FormattedMessage id="year" />,
			accessor: 'cars[0].year',
			maxWidth: 50,
		},
	]

	if (config.isDisplayCapacity === true)
		columns.push({
			Header: <FormattedMessage id="capacity" />,
			accessor: row => (!row.isRiders ? row.cars[0].capacity : '-'),
			id: 'capacity',
			maxWidth: 50,
		})

	if (config.isMultiRaces || config.isRiders)
		columns.push({
			Header: <FormattedMessage id="race" />,
			accessor: row => (row.isRiders ? config.ridersName : row.race.text),
			id: 'race',
			maxWidth: 80,
		})

	if (config.rallyType === 'TAO') {
		columns.push({
			Header: <FormattedMessage id="period" />,
			id: 'period',
			accessor: row => row.cars[0].period,
			maxWidth: 50,
		})
		columns.push({
			Header: <FormattedMessage id="plateau" />,
			id: 'plateau',
			accessor: row => row.cars[0].plateau,
			maxWidth: 50,
		})
	}

	if (config.isDisplayAVG === true)
		columns.push({
			Header: <FormattedMessage id="avg" />,
			id: 'avg',
			accessor: row => (row.race?.isAvg ? row.cars[0].averages[0].avgText : '-'),
			maxWidth: 50,
		})

	if (config.isDisplayGroup === true && config.rallyType !== 'TAO')
		columns.push({
			Header: <FormattedMessage id="group" />,
			id: 'group',
			accessor: row => (row.race?.isGroup ? row.cars[0].groupDisplay : '-'),
			maxWidth: 50,
		})

	if (config.isDisplayClass === true)
		columns.push({
			Header: <FormattedMessage id="class" />,
			id: 'class',
			accessor: row => (row.race?.isClass ? row.cars[0].classDisplay : '-'),
			maxWidth: 50,
		})

	if (config.isDisplayCoef === true && config.rallyType !== 'TAO')
		columns.push({
			Header: <FormattedMessage id="coef" />,
			id: 'coef',
			accessor: row => (row.race?.isCoef ? row.cars[0].coefficientDisplay : '-'),
			maxWidth: 50,
			Cell: ({ cell }) => <i>{cell.value}</i>,
		})

	if (config.isDisplayTeam === true)
		columns.push({
			Header: <FormattedMessage id="team" />,
			accessor: 'racingTeam',
			maxWidth: 150,
		})

	if (config.rallyType === 'TAO') {
		columns.push({
			Header: 'G-C',
			id: 'groupClass',
			accessor: row =>
				row.race.isScratch ? `${row.cars[0].groupDisplay}${row.cars[0].classDisplay}` : '-',
			maxWidth: 50,
		})

		columns.push({
			Header: 'Coef',
			id: 'coefficientDisplay',
			accessor: row => (row.race.isScratch ? `${row.cars[0].coefficientDisplay}` : '-'),
			maxWidth: 50,
		})
	}

	if (config.rallyType === 'DKC') {
		const rookieLogoPath = `races/${config.name}rookie.png`

		columns.splice(1, 0, {
			Header: <FormattedMessage id="experience" />,
			accessor: 'isRookie',
			Cell: ({ cell }) =>
				cell.value === true ? <Image publicId={rookieLogoPath} width="20" height="25" /> : null,
			maxWidth: 25,
		})
		columns.splice(0, 0, {
			Header: () => null,
			accessor: 'vehicle',
			Cell: ({ cell }) =>
				cell.value === 'CAR' ? (
					<DriveEta style={{ color: '#757575' }} />
				) : (
					<LocalShipping style={{ color: '#757575' }} />
				),
			maxWidth: 30,
			sticky: 'left',
		})
	}

	return (
		<div>
			<CssBaseline />
			<MUITable columns={columns} data={teams} type="startList" />
		</div>
	)
}

export default StartList
