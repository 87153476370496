import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { MenuRT, FooterRT } from '../components/SubMenus'
import { MUITable, SelectColumnFilter } from '../components/MUITable'
import { useConfig, useRaces, useStateWithLocalStorage } from '../hooks'
import { pointsToTime } from '../utils/timeConverter'
import { filterRTWithPosition, filterOVWithPosition } from '../utils/filters'

const useStyles = props =>
	makeStyles(theme => ({
		container: {
			height: '100%',
		},
		menu: {
			height: `${props.isMix ? '72px' : '108px'}`,
		},
		table: { height: `calc(100vh - ${props.isMix ? '184px' : '220px'})` },
		footer: {
			height: '48px',
		},
		content: {
			height: 'auto',
		},
		total: {
			//color: theme.palette.primary.main
		},
		numberSpan: {
			display: 'inline-block',
			textAlign: 'right',
			width: '40px',
		},
	}))

const RANKING_RT_QUERY = gql`
	query rankingRTQuery($groupID: ID) {
		rankingRT(groupID: $groupID) {
			position
			raceNumber
			teamName
			isRookie
			coefficientDisplay
			model
			avg
			avgText
			period
			plateau
			isCompleted
			refTimeCircuit
			results
			cones
			gates
			total
			difPrevious
			difFirst
		}
	}
`

const RANKING_RTOV_QUERY = gql`
	query rankingRTOVQuery($groupID: ID) {
		rankingRTOV(groupID: $groupID) {
			position
			raceNumber
			teamName
			coefficientDisplay
			model
			avg
			period
			jokersOV
			totalOV
			difPrevious
			difFirst
		}
	}
`

function RankingRT() {
	const { config } = useConfig()
	const { races } = useRaces()
	const { rtPrefix, isTimeTotal, isDecimal, mixDisplayRaces: isMix } = config
	const classes = useStyles({ isMix })()
	const [selectedRace, setSelectedRace] = useStateWithLocalStorage('selectedRace')
	const localStorageKey = isMix ? 'selectedRT' : `selectedRT${selectedRace}`
	const [selectedRT, setSelectedRT] = useStateWithLocalStorage(localStorageKey)
	const [race, setRace] = useState(null)
	const [stages, setStages] = useState([])
	const [isData, setIsData] = useState(false)
	const [rankingRT, setRankingRT] = useState([])
	const [rankingRTOV, setRankingRTOV] = useState([])
	const [viewTypeRT, setViewTypeRT] = useStateWithLocalStorage('viewTypeRT', 'summary')
	const [propsRT, setPropsRT] = useState({})

	const { loading: rankingRTLoading, error: rankingRTError, data: rankingRTData } = useQuery(
		RANKING_RT_QUERY,
		{
			skip: !selectedRT,
			pollInterval: config.pollInterval,
			variables: { groupID: selectedRT },
		}
	)
	const { loading: rankingRTOVLoading, error: rankingRTOVError, data: rankingRTOVData } = useQuery(
		RANKING_RTOV_QUERY,
		{
			skip: !selectedRT || !config.isPartialOV,
			pollInterval: config.pollInterval,
			variables: { groupID: selectedRT },
		}
	)

	useEffect(() => {
		if (!rankingRTLoading && rankingRTData) {
			setRankingRT(rankingRTData.rankingRT)
			setIsData(true)
		}
		if (!rankingRTOVLoading && rankingRTOVData) {
			setRankingRTOV(rankingRTOVData.rankingRTOV)
		}
	}, [rankingRTLoading, rankingRTOVLoading, rankingRTData, rankingRTOVData])

	useEffect(() => {
		if (selectedRace) {
			const newRace = races.find(race => race.id.toString() === selectedRace.toString())
			setRace(newRace)
		}
	}, [selectedRace, races])

	if (rankingRTLoading || rankingRTOVLoading) return 'Loading...'
	if (rankingRTError || rankingRTOVError)
		return `Error! ${rankingRTError && rankingRTError.message} ${
			rankingRTOVError && rankingRTOVError.message
		}`

	const columnsRT = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			sticky: 'left',
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			Cell: ({ value }) => (
				<span className={classes.numberSpan}>
					<strong>{value || '-'}</strong>
				</span>
			),
		},
		{
			Header: '#',
			accessor: 'raceNumber',
			sticky: 'left',
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			Cell: ({ value }) => <span className={classes.numberSpan}>{value}</span>,
		},
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'teamName',
			Cell: ({ cell }) => <div dangerouslySetInnerHTML={{ __html: cell.value }} />,
		},
	]

	if (viewTypeRT === 'detailed') {
		if (config.isDisplayCarRT) {
			columnsRT.push({
				Header: <FormattedMessage id="car" />,
				accessor: 'model',
			})
		}

		if (config.isDisplayAVG && (isMix || race?.isAvg)) {
			columnsRT.push({ Header: <FormattedMessage id="avg" />, accessor: 'avgText', maxWidth: 60 })
		}

		if (config.isDisplayCoef === true && (isMix || race?.isCoef)) {
			columnsRT.push({
				Header: <FormattedMessage id="coef" />,
				accessor: 'coefficientDisplay',
				Cell: ({ cell }) => <i>{cell.value}</i>,
			})
		}

		if (config.rallyType === 'TAO') {
			if (propsRT.isCircuit) {
				columnsRT.push({
					Header: <FormattedMessage id="period" />,
					id: 'period',
					accessor: 'period',
					Filter: SelectColumnFilter,
					filter: filterRTWithPosition,
				})

				columnsRT.push({
					Header: <FormattedMessage id="plateau" />,
					id: 'plateau',
					accessor: 'plateau',
					Filter: SelectColumnFilter,
					filter: filterRTWithPosition,
				})
			} else {
				columnsRT.push({
					Header: <FormattedMessage id="period" />,
					id: 'period',
					accessor: 'period',
					Filter: SelectColumnFilter,
					filter: filterRTWithPosition,
					width: 80,
				})
			}
		}
		if (propsRT.isCircuit === true && !race?.isScratch) {
			columnsRT.push({
				Header: 'Ref Time',
				id: 'refTimeCirctuit',
				accessor: row => pointsToTime(row.refTimeCircuit, isDecimal),
				minWidth: 80,
			})
		}
		if (stages && !race?.isScratch) {
			for (const [i, value] of stages.entries()) {
				const colWidth =
					value.substring(0, 4) === 'Dif.' || value.substring(0, 2) === 'CL' ? 120 : 60
				columnsRT.push({
					Header: <span className={classes.numberSpan}>{value}</span>,
					accessor: 'results[' + i + ']',
					width: colWidth,
					id: value,
					Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
				})
			}
		}
		if (propsRT.isShow) {
			columnsRT.push({
				Header: 'Cones',
				accessor: 'cones',
				maxWidth: 70,
			})
			columnsRT.push({
				Header: 'Gates',
				accessor: 'gates',
				maxWidth: 70,
			})
		}
	}
	columnsRT.push({
		Header: (
			<span className={classes.numberSpan}>
				{race?.isScratch ? <FormattedMessage id="time" /> : <FormattedMessage id="total" />}
			</span>
		),
		id: 'total',
		accessor: row =>
			config.rallyType === 'TAO' && !row.isCompleted
				? 'NT'
				: isTimeTotal
				? row.total === 0
					? '00:00:00'
					: pointsToTime(row.total, isDecimal)
				: row.total,
		//maxWidth: isTimeTotal ? 120 : 50,
		//sticky: 'right',
		Cell: row => (
			<span className={classes.numberSpan}>
				<strong>{row.value}</strong>
			</span>
		),
	})
	if (viewTypeRT === 'detailed') {
		columnsRT.push({
			Header: <FormattedMessage id="difference" />,
			accessor: 'difPrevious',
			//maxWidth: isTimeTotal ? 120 : 80,
		})
		columnsRT.push({
			Header: <FormattedMessage id="differenceFirst" />,
			accessor: 'difFirst',
			//maxWidth: isTimeTotal ? 120 : 80,
		})
	}
	const columnsRTOV = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			sticky: 'left',
			Cell: ({ value }) => (
				<span className={classes.numberSpan}>
					<strong>{value || '-'}</strong>
				</span>
			),
		},
		{
			Header: '#',
			accessor: 'raceNumber',
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			sticky: 'left',
			Cell: ({ value }) => <span>{value}</span>,
		},
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			//minWidth: 175,
			//maxWidth: 200,
		},
	]

	if (config.rallyType === 'TAO') {
		columnsRTOV.push({
			Header: <FormattedMessage id="period" />,
			id: 'period',
			accessor: 'period',
			Filter: SelectColumnFilter,
			filter: filterOVWithPosition,
			width: 80,
		})
	}
	columnsRTOV.push(
		{
			Header: <FormattedMessage id="total" />,
			id: 'total',
			accessor: row =>
				isTimeTotal
					? pointsToTime(row.totalOV, isDecimal)
					: Number(parseFloat(row.totalOV).toPrecision(12)),
			//maxWidth: isTimeTotal ? 120 : 50,
			//sticky: 'right',
			Cell: row => <strong className={classes.total}>{row.value}</strong>,
		},
		{
			Header: <FormattedMessage id="difference" />,
			id: 'difPrevious',
			accessor: 'difPrevious',
			//maxWidth: isTimeTotal ? 120 : 80,
		},
		{
			Header: <FormattedMessage id="differenceFirst" />,
			id: 'difFirst',
			accessor: 'difFirst',
			//maxWidth: isTimeTotal ? 120 : 80,
		}
	)

	const rankingRTTable =
		viewTypeRT === 'rankingRTOV' ? (
			<MUITable columns={columnsRTOV} data={rankingRTOV} type="rankingRT" isMix={isMix} />
		) : (
			<MUITable columns={columnsRT} data={rankingRT} type="rankingRT" isMix={isMix} />
		)

	return (
		<div className={classes.container}>
			<div className={classes.menu}>
				<MenuRT
					rankingSelectedRT={selectedRT}
					setRankingSelectedRT={setSelectedRT}
					rankingSelectedRace={selectedRace}
					setRankingSelectedRace={setSelectedRace}
					setRankingStages={setStages}
					setRankingPropsRT={setPropsRT}
					isData={isData}
				/>
			</div>

			{rankingRT && selectedRT && (race || isMix) && (
				<React.Fragment>
					<div className={classes.table}>{rankingRTTable}</div>
					<div className={classes.footer}>
						<FooterRT
							viewTypeRT={viewTypeRT}
							setViewTypeRT={setViewTypeRT}
							className={classes.footerRT}
							rtPrefix={rtPrefix}
							//style={{ position: 'sticky' }}
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	)
}

export default RankingRT
